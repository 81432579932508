import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { map, tap } from 'rxjs/operators';
import { get } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { GeneralSettingDTO } from './model';

@Injectable({
	providedIn: 'root',
})
export class AppConfigService {
	public appConfig: any = {};

	private _http = inject(HttpClient);
	private _document = inject(DOCUMENT);
	private _translate = inject(TranslateService);

	get appLocale(): any {
		return get(this.appConfig, 'locale') || 'fr_FR';
	}

	get flexMonsterLicense(): any {
		return get(this.appConfig, 'flexMonsterLicense') || '';
	}

	get appLang(): any {
		return get(this.appConfig, 'lang') || 'fr';
	}

	get isRemoteEyeViewer(): any {
		return get(this.appConfig, 'remoteEyeViewer') || false;
	}

	get remoteEyeUsername(): any {
		return get(this.appConfig, 'remoteEyeUsername') || 'admin';
	}

	get remoteEyePassword(): any {
		return get(this.appConfig, 'remoteEyePassword') || 'admin';
	}

	get displayDateInput(): string {
		return get(this.appConfig, 'displayDateInput') || 'L';
	}

	get dateFormat(): string {
		return get(this.appConfig, 'dateFormat') || 'dd/MM/yyyy';
	}

	get currencyFormat(): string {
		return get(this.appConfig, 'currencyFormat') || 'DH';
	}

	get numberFormat(): string {
		return get(this.appConfig, 'numberFormat') || '0,0';
	}

	get syngoviaActivated(): boolean {
		return get(this.appConfig, 'syngoviaActivated') || false;
	}

	get almaActivated(): boolean {
		return get(this.appConfig, 'almaActivated') || false;
	}

	get eFactActivated(): boolean {
		return get(this.appConfig, 'efactActivated') || false;
	}

	get eHealthBoxActivated(): boolean {
		return get(this.appConfig, 'ehealthBoxActivated') || false;
	}

	get dateTimeFormat(): string {
		return get(this.appConfig, 'dateTimeFormat') || 'dd/MM/yyyy HH:mm';
	}

	get momentDateFormat(): string {
		return get(this.appConfig, 'momentDateFormat') || 'DD/MM/YYYY';
	}

	get momentDateTimeFormat(): string {
		return (
			get(this.appConfig, 'momentDateTimeFormat') || 'DD/MM/YYYY HH:mm'
		);
	}

	get logo(): string {
		return get(this.appConfig, 'logo') || 'fireris.png';
	}

	get paragraphAutocompleteEnabled(): boolean {
		return get(this.appConfig, 'paragraphAutocompleteEnabled') || false;
	}

	get generalSetting(): GeneralSettingDTO {
		return get(this.appConfig, 'generalSetting') || {};
	}

	get centers(): any[] {
		return get(this.appConfig, 'centers') || [];
	}

	load(url: string): Promise<boolean> {
		console.time(url);
		return firstValueFrom(
			this._http.get(url).pipe(
				tap(data => {
					this.appConfig = data;
					this._document.documentElement.setAttribute(
						'lang',
						this.appLang
					);
					this._translate.addLangs(['en', 'fr', 'nl']);
					this._translate.setDefaultLang('fr');
					this._translate.use(this.appLang);

					console.timeEnd(url);
				}),
				map(() => true)
			)
		);
	}
}
